<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app>
            <!-- <router-link to="/" style="text-decoration: none"> -->
            <a :href="mainWebsiteURL" style="text-decoration: none">
                <v-toolbar-title class="text-overline" style="font-size: 16px!important;">
                    <span class="teal--text">Brand</span><span class="green--text text--lighten-2">Profile</span>
                </v-toolbar-title>
                <!-- <v-toolbar-title>
                    <g-link to="/" style="text-decoration: none;">
                        <g-image alt="BrandProfile" src="~/assets/logo/brandprofile/BrandProfile_light_256x48.png" class="mr-1 float-left" width="128" height="24" fit="inside"/>
                    </g-link>
                </v-toolbar-title> -->
            </a>
            <!-- </router-link> -->
            <!-- 240x48, 180x36, 120x24, 90x18 -->
            <!-- <router-link to="/">
                <v-img
                alt="Webauthz"
                class="shrink mr-2"
                contain
                :src="require('@/assets/logo/Webauthz_nobg_240x48.png')"
                transition="scale-transition"
                width="120"
                height="24"
                />
                </router-link> -->
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="teal"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <v-btn icon @click="search" color="teal">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
            </v-btn>

            <!-- <div v-show="$vuetify.breakpoint.smAndUp">
                <v-tabs slider-color="teal" right>
                    <v-tab to="/" v-show="false"></v-tab>
                    < ! - - <v-tab to="/about">About</v-tab>
                    <v-tab to="/login">Login</v-tab> - - >
                </v-tabs>
            </div> -->
            <!-- <div v-show="$vuetify.breakpoint.xsOnly"> -->
            <!-- <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">
                        <font-awesome-icon right class="teal--text" icon="bars" fixed-width/>
                    </v-btn>
                </template>
                <v-tabs vertical slider-color="teal">
                    <v-tab to="/" v-show="false"></v-tab>
                    < ! - - <v-tab to="/about">About</v-tab>
                    <v-tab to="/login">Login</v-tab> - - >
                </v-tabs>
            </v-menu> -->
            <!-- </div> -->
        </v-app-bar>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
// import Avatar from '@/components/Avatar.vue';

export default {
    // components: {
    //     Avatar,
    // },
    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        },
    },
    methods: {
        search() {
            if (this.$route.name === 'search') {
                // reset the search input to empty string
                this.$bus.$emit('search', '');
            } else {
                this.$router.push({ name: 'search' /* , query: { q: this.searchQuery } */ });
            }
        },
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
