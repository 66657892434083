import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import fontawesome from './fontawesome';
// import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const { mapFontAwesomeIcons } = fontawesome;

export default new Vuetify({
    // https://vuetifyjs.com/en/customization/theme/ and https://vuetifyjs.com/en/styles/colors/
    theme: {
        themes: {
            light: {
                primary: colors.teal.darken2,
            },
        },
    },
    options: {
        customProperties: true,
    },
    // https://vuetifyjs.com/en/getting-started/quick-start#font-installation
    icons: {
        iconfont: 'faSvg', // https://vuetifyjs.com/en/customization/icons#install-font-awesome-svg-icons
        /* non-svg icons can use the simple values:
        values: {
            first: 'fas fa-angle-double-left',
            prev: 'fas fa-angle-left',
            last: 'fas fa-angle-double-right',
            next: 'fas fa-angle-right',
            sort: 'fas fa-sort',
            dropdown: 'fas fa-caret-down',
        },
        */
        // svg icons must be rendered by the FontAwesomeIcon component (https://vuetifyjs.com/en/customization/icons#custom-font-awesome-pro-icons)
        // to use a custom icon somewhere, add it to this list like newIcon: ['fas', 'new-icon'] and then
        // reference it in the component like  customIcon: '$vuetify.icons.newIcon'
        values: {
            ...mapFontAwesomeIcons({
                first: ['fas', 'angle-double-left'],
                prev: ['fas', 'angle-left'],
                next: ['fas', 'angle-right'],
                last: ['fas', 'angle-double-right'],
                checkboxOn: ['fas', 'check-square'],
                checkboxOff: ['far', 'square'],
                checkboxIndeterminate: ['fas', 'minus-square'],
                file: ['fas', 'paperclip'],
                clear: ['fas', 'times'],
                expand: ['fas', 'caret-down'],
                /*
                caret-down is centered, so works nicely with v-data-table where it
                flips the icon depending on the sort direction, whereas the
                sort-down icon is aligned bottom so appears to move up and down when
                being flipped by v-data-table
                */
                sort: ['fas', 'caret-down'],
                dropdown: ['fas', 'caret-down'],
            }),
        },
    },
});
