import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/search',
            name: 'search',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        },
        {
            path: '/view/:alias',
            name: 'brand-view-profile',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "brand-view-profile" */ '../views/brand/ViewProfile.vue'),
        },
        {
            path: '/view/:alias/logo',
            name: 'brand-view-logo',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "brand-view-logo" */ '../views/brand/ViewLogo.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
