<template>
    <v-footer dark padless app absolute class="no-print">
        <v-row justify="center" no-gutters>
            <!-- <v-col cols="12" class="teal">
                <span class="text-body-2 px-2 py-0 ma-0">
                    This software is still under development. Please
                    <a href="https://liberty.io/contact/" class="text-body-2 white--text">contact us</a>
                    with questions or comments.
                </span>
            </v-col> -->
            <!-- <v-col cols="12" class="pa-0 dark" style="text-align: center">
                <router-link class="font-weight-light text-body-2 white--text" to="/about/terms">Terms of Use</router-link>
                <router-link class="font-weight-light text-body-2 white--text" to="/about/privacy">Privacy Policy</router-link>
            </v-col> -->
            <v-col cols="12" class="text-caption pa-0 dark font-weight-light" style="text-align: center">
                    <span class="grey--text text--lighten-3">Powered by</span> <a :href="mainWebsiteURL" class="teal--text text--lighten-3">BrandProfile</a>
            </v-col>
        </v-row>
    </v-footer>
</template>
<style>
@media print {
    .no-print {
        display: none !important;
    }
}
</style>
<script>
export default {
    computed: {
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        },
    },
};
</script>
