import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        loadingMap: {},
        brand: null,
        palette: null,
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
    },
    mutations: {
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        brand(state, brand) {
            state.brand = brand;
        },
        palette(state, palette) {
            state.palette = palette;
        },
    },
    actions: {
        async loadBrand({ commit }, { alias }) {
            try {
                commit('loading', { loadBrand: true });
                const response = await client.main().brand.get({ alias });
                commit('brand', response);
            } catch (err) {
                console.error('loadBrand failed', err);
                commit('brand', null);
            } finally {
                commit('loading', { loadBrand: false });
            }
        },
        async loadPalette({ commit }, { alias, mode = 'light' }) {
            try {
                commit('loading', { loadPalette: true });
                const match = {
                    alias,
                    mode,
                };
                const response = await client.main().palette.get(match);
                commit('palette', response);
            } catch (err) {
                console.error('loadPalette failed', err);
                commit('palette', null);
            } finally {
                commit('loading', { loadPalette: false });
            }
        },
    },
});
