// import { Client as AccountClient } from './client/account';
import { Client as MainClient } from './client/main';
// import { Client as UserClient } from './client/user';

/* eslint-disable dot-notation */

const orgClientMap = {}; // org id -> client configured for that organization
const client = {
    // alias(id) {
    //     const idx = `alias_${alias}`;
    //     if (!orgClientMap[idx]) {
    //         orgClientMap[idx] = new AccountClient({ endpoint: '/', serviceEndpoint: '/api/browser', alias });
    //     }
    //     return orgClientMap[idx];
    // },
    main() {
        if (!orgClientMap['main']) {
            orgClientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return orgClientMap['main'];
    },
};

export default client;
